import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom';

import cafe from "../../assets/images/landing/cafe.png";
import construction from "../../assets/images/landing/construction.jpg";
import fashion from "../../assets/images/landing/fashion.png";
import market from "../../assets/images/landing/market.png";
import motors from "../../assets/images/landing/motors.png";
import store from "../../assets/images/landing/store1.jpg";
import magventure from "../../assets/images/landing/magventure.jpg";
import AOS from "aos";
import Animation from "./Animation";
import "aos/dist/aos.css";


import FoundationImage1 from "../../assets/images/foundation/foundation (1).jpg";
import FoundationImage2 from "../../assets/images/foundation/foundation (2).jpg";
import FoundationImage3 from "../../assets/images/foundation/foundation (3).jpg";
import FoundationImage4 from "../../assets/images/foundation/foundation (4).jpg";
import FoundationImage5 from "../../assets/images/foundation/foundation (5).jpg";
import FoundationImage6 from "../../assets/images/foundation/foundation (6).jpg";
import FoundationImage7 from "../../assets/images/foundation/foundation (7).jpg";
import FoundationImage8 from "../../assets/images/foundation/foundation (8).jpg";
import FoundationImage9 from "../../assets/images/foundation/foundation (9).jpg";
import FoundationImage10 from "../../assets/images/foundation/foundation (10).jpg";
import FoundationImage11 from "../../assets/images/foundation/foundation (11).jpg";
import FoundationImage12 from "../../assets/images/foundation/foundation (12).jpg";
import FoundationImage13 from "../../assets/images/foundation/foundation (13).jpg";
import FoundationImage14 from "../../assets/images/foundation/foundation (14).jpg";
import FoundationImage15 from "../../assets/images/foundation/foundation (15).jpg";

import FoundationImage28 from "../../assets/images/foundation/foundation (28).jpg";
import FoundationImage29 from "../../assets/images/foundation/foundation (29).jpg";
import FoundationImage30 from "../../assets/images/foundation/foundation (30).jpg";
import FoundationImage31 from "../../assets/images/foundation/foundation (31).jpg";
import FoundationImage32 from "../../assets/images/foundation/foundation (32).jpg";
import FoundationImage33 from "../../assets/images/foundation/foundation (33).jpg";
import FoundationImage34 from "../../assets/images/foundation/foundation (34).jpg";
import FoundationImage35 from "../../assets/images/foundation/foundation (35).jpg";
import FoundationImage36 from "../../assets/images/foundation/foundation (36).jpg";
import FoundationImage37 from "../../assets/images/foundation/foundation (37).jpg";



const images = [
    FoundationImage28,
    FoundationImage29,
    FoundationImage30,
    FoundationImage31,
    FoundationImage32,
    FoundationImage33,
    FoundationImage34,
    FoundationImage35,
    FoundationImage36,
    FoundationImage37,

    FoundationImage1,
    FoundationImage2,
    FoundationImage3,
    FoundationImage4,
    FoundationImage5,
    FoundationImage6,
    FoundationImage7,
    FoundationImage8,
    FoundationImage9,
    FoundationImage10,
    FoundationImage11,
    FoundationImage12,
    FoundationImage13,
    FoundationImage14,
    FoundationImage15,
];
const Media = () => {


    useEffect(() => {
        document.title = "MAG VENTURES";
        AOS.init({
            easing: "ease-in-out",
        });
    }, []);

    return (
        <>
            <Animation />
            <div className="page-header media">
                <div>
                    <h1>Media</h1>
                    <p
                        data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-easing="ease-in-out"
                    >
                        Where Investment Thrive and Capitals Multiply
                    </p>
                </div>
            </div>

            <section className="simple-section">
                <div className="container">
                    <div className='row g-4'>
                        <div className="col-md-6">
                            <div className="blog-item"
                                data-aos="fade-up"
                                data-aos-delay="100"
                                data-aos-easing="ease-in-out"
                            >
                                <a href='https://youtu.be/3cDlBxKBcWg?si=2gCqQVh6cqYTZYFX'>
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/3cDlBxKBcWg?si=rkxzoODiVjREMSlp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </a>
                                <span>25 <br /> JAN</span>
                                <div className="content">
                                    <h3>Mag Ventures Press Conference </h3>
                                    <h6>Venture Of Success</h6>

                                    <NavLink href='https://youtu.be/3cDlBxKBcWg?si=2gCqQVh6cqYTZYFX'>READ MORE</NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="blog-item"
                                data-aos="fade-up"
                                data-aos-delay="100"
                                data-aos-easing="ease-in-out"
                            >
                                <a href='https://youtu.be/KpZSKiPgokM?si=PYpj-_wfOdmf_fS-'>
                                    <iframe width="100%" height="315" src="https://www.youtube.com/embed/KpZSKiPgokM?si=rPpmIX-7dzhEJVvz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                </a>
                                <span>25 <br /> JAN</span>
                                <div className="content">
                                    <h3>Mag Ventures Unveils Mag Cattle Farm in for Premium Organic Meat Trading</h3>
                                    <h6>Venture Of Success</h6>

                                    <a href='https://youtu.be/KpZSKiPgokM?si=PYpj-_wfOdmf_fS-'>READ MORE</a>
                                </div>
                            </div>
                        </div>
                        {images.map((image, index) => (
                            <div className="col-md-4" key={index} data-aos-delay={`${index}00`}
                                data-aos-easing="ease-in-out">
                                <img src={image} alt={`Foundation ${index + 1}`} className="img-fluid rounded" />
                            </div>
                        ))}
                        <div className="col-md-4">
                            <div className="blog-item"
                                data-aos="fade-up"
                                data-aos-delay="200"
                                data-aos-easing="ease-in-out"
                            >
                                <NavLink onClick={() => window.scrollTo(0, 0)} to="/media/mag-store">
                                    <img src={store} alt="" />
                                </NavLink>
                                <span>25 <br /> JAN</span>
                                <div className="content">
                                    <h3>MAG STORE</h3>
                                    <h6>Unlock your Apple Adventure</h6>
                                    <p>Mag Store, a flagship project under MAG VENTURES, seamlessly combines the
                                        convenience of e-commerce with the excitement of physical stores.</p>
                                    <NavLink onClick={() => window.scrollTo(0, 0)} to="/media/mag-store">READ MORE</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="blog-item"
                                data-aos="fade-up"
                                data-aos-delay="300"
                                data-aos-easing="ease-in-out"
                            >
                                <NavLink onClick={() => window.scrollTo(0, 0)} to="/media/mag-builders-and-developers">
                                    <img src={construction} alt="" />
                                </NavLink>
                                <span>25 <br /> JAN</span>
                                <div className="content">
                                    <h3>MAG BUILDERS & DEVELOPERS</h3>
                                    <h6>Build your Dreams</h6>
                                    <p>MAG VENTURES serves as the dynamic engine propelling MAG VENTURES' diverse portfolio into every facet of daily life.</p>
                                    <NavLink onClick={() => window.scrollTo(0, 0)} to="/media/mag-builders-and-developers">READ MORE</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="blog-item"
                                data-aos="fade-up"
                                data-aos-delay="400"
                                data-aos-easing="ease-in-out"
                            >
                                <NavLink onClick={() => window.scrollTo(0, 0)} to="/media/mag-super-market">
                                    <img src={market} alt="" />
                                </NavLink>
                                <span>25 <br /> JAN</span>
                                <div className="content">
                                    <h3>MAG SUPERMARKET</h3>
                                    <h6>Your One-Stop Shop for All Your Needs</h6>
                                    <p>
                                        Mag SuperMarket, an integral part of MAG VENTURES, is committed to providing
                                        customers with a seamless shopping experience.
                                    </p>
                                    <NavLink onClick={() => window.scrollTo(0, 0)} to="/media/mag-super-market">READ MORE</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="blog-item"
                                data-aos="fade-up"
                                data-aos-delay="500"
                                data-aos-easing="ease-in-out"
                            >
                                <NavLink onClick={() => window.scrollTo(0, 0)} to="/media/mag-cafe">
                                    <img src={cafe} alt="" />
                                </NavLink>
                                <span>25 <br /> JAN</span>
                                <div className="content">
                                    <h3>MAG'S CAFE</h3>
                                    <h6>Taste the Magic</h6>
                                    <p>Mag's Cafe, a charming haven under MAG VENTURES, invites patrons to relax and
                                        indulge in a delightful culinary experience. </p>
                                    <NavLink onClick={() => window.scrollTo(0, 0)} to="/media/mag-cafe">READ MORE</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="blog-item"
                                data-aos="fade-up"
                                data-aos-delay="600"
                                data-aos-easing="ease-in-out"
                            >
                                <NavLink onClick={() => window.scrollTo(0, 0)} to="/media/mag-motors">
                                    <img src={motors} alt="" />
                                </NavLink>
                                <span>25 <br /> JAN</span>
                                <div className="content">
                                    <h3>MAG MOTORS</h3>
                                    <h6>Unleash Your Driving Passion</h6>
                                    <p>Mag Motors, a flagship entity under MAG VENTURES, is more than just a dealership
                                        for cars and auto parts. </p>
                                    <NavLink onClick={() => window.scrollTo(0, 0)} to="/media/mag-motors">READ MORE</NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="blog-item"
                                data-aos="fade-up"
                                data-aos-delay="700"
                                data-aos-easing="ease-in-out"
                            >
                                <NavLink onClick={() => window.scrollTo(0, 0)} to="/media/eleganz-by-mag">
                                    <img src={fashion} alt="" />
                                </NavLink>
                                <span>25 <br /> JAN</span>
                                <div className="content">
                                    <h3>ELEGANZ BY MAG</h3>
                                    <h6>Embrace Your Inner Elegance</h6>
                                    <p>Eleganz by Mag represents the intersection of beauty and fashion, curating a world of
                                        endless possibilities for individuals looking to express their unique style. </p>
                                    <NavLink onClick={() => window.scrollTo(0, 0)} to="/media/eleganz-by-mag">READ MORE</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Media
